import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "content card" }
const _hoisted_5 = { class: "p-mb-4" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "p-grid" }
const _hoisted_8 = { class: "p-field p-col-6" }
const _hoisted_9 = {
  key: 1,
  class: "regular"
}
const _hoisted_10 = {
  key: 2,
  class: "regular p-mt-3"
}
const _hoisted_11 = { class: "p-grid" }
const _hoisted_12 = { class: "p-field p-col-6" }
const _hoisted_13 = {
  key: 1,
  class: "regular"
}
const _hoisted_14 = { class: "p-field p-col-6" }
const _hoisted_15 = {
  key: 1,
  class: "regular"
}
const _hoisted_16 = {
  key: 0,
  class: "p-field p-col-6"
}
const _hoisted_17 = { class: "regular" }
const _hoisted_18 = {
  key: 1,
  class: "p-field p-col-6"
}
const _hoisted_19 = { class: "regular" }
const _hoisted_20 = {
  key: 2,
  class: "p-field p-col-6"
}
const _hoisted_21 = { class: "p-field-radio" }
const _hoisted_22 = { class: "p-field-radiobutton" }
const _hoisted_23 = { for: "is_active_on" }
const _hoisted_24 = { class: "p-field-radiobutton" }
const _hoisted_25 = { for: "is_active_off" }
const _hoisted_26 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_VField = _resolveComponent("VField")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_VForm = _resolveComponent("VForm")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_VForm, {
    as: "div",
    id: "service_edit_page",
    class: "page standard vertical-form"
  }, {
    default: _withCtx(({ handleSubmit, isSubmitting, meta: { valid } }) => [
      _createVNode("section", _hoisted_1, [
        _createVNode("div", null, [
          _createVNode("h1", null, _toDisplayString(_ctx.$t('provider.technicians.detail.title')), 1),
          _createVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('provider.technicians.detail.subtitle')), 1)
        ]),
        _createVNode("div", _hoisted_3, [
          (!_ctx.isNew)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: _ctx.$t('provider.technicians.detail.btn_delete_tech_label'),
                class: "p-button-danger",
                icon: "pi pi-trash",
                iconPos: "right",
                onClick: _ctx.dissociateTechnician
              }, null, 8, ["label", "onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            label: _ctx.$t('btn_save_label'),
            class: "p-button-success",
            icon: "pi pi-save",
            iconPos: "right",
            disabled: !valid || isSubmitting,
            type: "submit",
            form: "edit_form"
          }, null, 8, ["label", "disabled"])
        ])
      ]),
      _createVNode("section", _hoisted_4, [
        _createVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('form_configuration_label')), 1),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock("h3", _hoisted_6, _toDisplayString(_ctx.$t('loading_label')), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode("form", {
          class: "form_content",
          onSubmit: ($event: any) => (handleSubmit($event, _ctx.doSave)),
          id: "edit_form"
        }, [
          _createVNode("div", _hoisted_7, [
            _createVNode("div", _hoisted_8, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('provider.technicians.detail.email_label')) + " ", 1),
                (!_ctx.isEmailReadOnly)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "provider.technicians.detail.email_label.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.isEmailReadOnly)
                ? (_openBlock(), _createBlock(_component_VField, {
                    key: 0,
                    name: "email",
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.email = $event)),
                    rules: "required|email"
                  }, {
                    default: _withCtx(({ field, meta: { dirty, valid } }) => [
                      _createVNode(_component_InputText, _mergeProps({ type: "email" }, field, {
                        disabled: isSubmitting,
                        class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                        placeholder: _ctx.$t('generic.email_label'),
                        onInput: _ctx.onEmailInput
                      }), null, 16, ["disabled", "class", "placeholder", "onInput"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue"]))
                : (_openBlock(), _createBlock("label", _hoisted_9, _toDisplayString(_ctx.model.email), 1)),
              (_ctx.isLoadingByEmail)
                ? (_openBlock(), _createBlock("label", _hoisted_10, _toDisplayString(_ctx.$t('loading_label')), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _withDirectives(_createVNode("div", _hoisted_11, [
            _createVNode("div", _hoisted_12, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('user_name')) + " ", 1),
                (!_ctx.isTechnicianExists)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "user_name.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.isTechnicianExists)
                ? (_openBlock(), _createBlock(_component_VField, {
                    key: 0,
                    name: "name",
                    modelValue: _ctx.model.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.model.name = $event)),
                    rules: "required"
                  }, {
                    default: _withCtx(({ field, meta: { dirty, valid } }) => [
                      _createVNode(_component_InputText, _mergeProps({ autofocus: "" }, field, {
                        disabled: isSubmitting,
                        class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                        placeholder: _ctx.$t('user_name')
                      }), null, 16, ["disabled", "class", "placeholder"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue"]))
                : (_openBlock(), _createBlock("label", _hoisted_13, _toDisplayString(_ctx.model.name), 1))
            ]),
            _createVNode("div", _hoisted_14, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('user_surname')) + " ", 1),
                (!_ctx.isTechnicianExists)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "user_surname.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.isTechnicianExists)
                ? (_openBlock(), _createBlock(_component_VField, {
                    key: 0,
                    name: "surname",
                    modelValue: _ctx.model.surname,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.model.surname = $event)),
                    rules: "required"
                  }, {
                    default: _withCtx(({ field, meta: { dirty, valid } }) => [
                      _createVNode(_component_InputText, _mergeProps(field, {
                        disabled: isSubmitting,
                        class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                        placeholder: _ctx.$t('user_surname')
                      }), null, 16, ["disabled", "class", "placeholder"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue"]))
                : (_openBlock(), _createBlock("label", _hoisted_15, _toDisplayString(_ctx.model.surname), 1))
            ]),
            (_ctx.isTechnicianExists)
              ? (_openBlock(), _createBlock("div", _hoisted_16, [
                  _withDirectives(_createVNode("label", null, null, 512), [
                    [_directive_t, 'provider.technicians.detail.services_label']
                  ]),
                  _createVNode("label", _hoisted_17, _toDisplayString(_ctx.servicesLabel), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isTechnicianExists)
              ? (_openBlock(), _createBlock("div", _hoisted_18, [
                  _withDirectives(_createVNode("label", null, null, 512), [
                    [_directive_t, 'provider.technicians.detail.provinces_label']
                  ]),
                  _createVNode("label", _hoisted_19, _toDisplayString(_ctx.provincesLabel), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.model.provider_data)
              ? (_openBlock(), _createBlock("div", _hoisted_20, [
                  _createVNode("label", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('generic.input_state_label')) + " ", 1),
                    _createVNode(_component_InfoIcon, { text: "generic.input_state_label.tooltip" })
                  ]),
                  _createVNode("div", _hoisted_21, [
                    _createVNode("div", _hoisted_22, [
                      _createVNode(_component_RadioButton, {
                        id: "is_active_on",
                        modelValue: _ctx.model.provider_data.is_active,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.model.provider_data.is_active = $event)),
                        value: true
                      }, null, 8, ["modelValue"]),
                      _createVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('active_label')), 1)
                    ]),
                    _createVNode("div", _hoisted_24, [
                      _createVNode(_component_RadioButton, {
                        id: "is_active_off",
                        modelValue: _ctx.model.provider_data.is_active,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.model.provider_data.is_active = $event)),
                        value: false
                      }, null, 8, ["modelValue"]),
                      _createVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('inactive_label')), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, _ctx.canShowForm]
          ])
        ], 40, ["onSubmit"]), [
          [_vShow, !_ctx.isLoading]
        ])
      ]),
      _createVNode("section", _hoisted_26, [
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_go_back_label'),
          class: "p-button-secondary",
          icon: "pi pi-arrow-left",
          iconPos: "left",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.back()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_save_label'),
          class: "p-button-success p-ml-auto",
          icon: "pi pi-save",
          iconPos: "right",
          disabled: !valid || isSubmitting,
          type: "submit",
          form: "edit_form"
        }, null, 8, ["label", "disabled"])
      ])
    ]),
    _: 1
  }))
}