import { City, Province, Region } from '@/model/api/Domain';
import { ProviderTechnician, ProviderTechnicianData } from '@/model/api/Provider';
import { Service } from '@/model/api/Service';
import { UserRoleEnum } from '@/model/enums/UserRoleEnum';
import { authStore } from '@/modules/auth/store';
import { debounce } from '@/utils/utils';
import { FormPage } from '@components';
import { domainService } from '@services/domain.service';
import { providerTechniciansService } from '@services/providerTechnicians.service';
import {
  email as isValidEmail
} from '@vee-validate/rules';

import {
  Form as VForm,
  Field as VField
} from 'vee-validate';

import { Options } from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import { TechniciansRoutesEnum } from '../../router';

@Options({
  components: {
    VForm,
    VField,
  }
})
export default class TechnicianPage extends FormPage {
  @Prop() readonly technicianId!: number;

  @Prop()
  readonly providerId: number;

  email: string = null;
  model: ProviderTechnician = new ProviderTechnician();
  filteredServices: { id: number, name: string }[] = [];
  filteredRegions: { id: number, name: string }[] = [];
  filteredProvinces: { id: number, name: string }[] = [];
  filteredCities: { id: number, name: string }[] = [];

  isLoading = false;
  isLoadingByEmail = false;
  isTechnicianExists = false;
  isEmailReadOnly = false;

  get isNew() {
    return this.technicianId == 0;
  }

  get canShowForm() {
    return !this.isLoadingByEmail && this.email && isValidEmail(this.email);
  }

  get servicesLabel() {
    return this.model.services.map(s => s.name).join(' - ') || '-';
  }

  get regionsLabel() {
    return this.model.regions.map(s => s.name).join(' - ') || '-';
  }

  get provincesLabel() {
    return this.model.provinces.map(s => s.name).join(' - ') || '-';
  }

  get citiesLabel() {
    return this.model.cities.map(s => s.name).join(' - ') || '-';
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  /*get selectedServices() {
    return this.model.services.map(x => {
      return { id: x.id, name: x.name };
    }) || [];
  }
  set selectedServices(values) {
    this.model.services = values as Service[];
  }

  get selectedRegions() {
    return this.model.regions.map(x => {
      return { id: x.id, name: x.name };
    }) || [];
  }
  set selectedRegions(values) {
    this.model.regions = values as Region[];
  }

  get selectedProvinces() {
    return this.model.provinces.map(x => {
      return { id: x.id, name: x.name };
    }) || [];
  }
  set selectedProvinces(values) {
    this.model.provinces = values as Province[];
  }

  get selectedCities() {
    return this.model.cities.map(x => {
      return { id: x.id, name: x.name };
    }) || [];
  }
  set selectedCities(values) {
    this.model.cities = values as City[];
  }*/

  get providerIdOrFallback(): number {
    if (authStore.getters.me.role == UserRoleEnum.PROVIDER) {
      return authStore.getters.me.entity_id;
    }
    return this.providerId;
  }

  private debounceEmail = null;

  onEmailInput() {
    if (!this.email || !isValidEmail(this.email)) return;

    this.isLoadingByEmail = true;
    providerTechniciansService.getByEmailSource?.cancel();

    this.debounceEmail = debounce(this.debounceEmail, async () => {
      try {
        //this.model = await providerTechniciansService.getByEmail(this.email);
        this.model = await providerTechniciansService.getByEmailProvider(this.email, this.providerId);
        if (!this.model.provider_data) this.defaultProviderData();
        this.isTechnicianExists = true;
      } catch (e) {
        this.model = new ProviderTechnician();
        this.defaultProviderData();
        this.isTechnicianExists = false;
      }

      this.isLoadingByEmail = false;
    });
  }

  async created() {
    this.defaultProviderData();

    if (!this.isNew) {
      this.isLoading = true;

      try {
        this.model = await providerTechniciansService.getByIdForEntityId(this.technicianId, this.providerIdOrFallback)
        this.email = this.model.email;
        this.isTechnicianExists = true;
        this.isEmailReadOnly = true;
      } catch (e) {
        this.$router.replace({ name: TechniciansRoutesEnum.TECHNICIAN_LIST, params: { providerId: this.providerIdOrFallback } });
      }
      this.isLoading = false;
    }

    this.modelFormChanged();
  }

  async doSave() {
    this.model.email = this.email;
    this.model.is_active = true;
    if (!this.model.entity_id) this.model.entity_id = this.providerIdOrFallback;

    await this.$waitFor(
      async () => {

        if (this.isNew && this.isTechnicianExists) {
          await providerTechniciansService.associate(this.model.id, this.providerIdOrFallback, this.model.provider_data.is_active);
        } else {
          if (this.isNew) {
            await providerTechniciansService.create(this.model);
          } else {
            await providerTechniciansService.updatePatch(this.model);
          }
        }

        this.modelFormChanged();

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    );
  }

  async dissociateTechnician() {
    this.$waitFor(
      async () => {

        await providerTechniciansService.dissociate(this.model.id, this.providerIdOrFallback);

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    );
  }

  private defaultProviderData() {
    this.model.provider_data = { is_active: true, provider_id: this.providerIdOrFallback };
  }

  /*async searchServices({ query }) {
    const resp = await domainService.getServicesForProviderAutocomplete(query);
    this.filteredServices = resp.data.map(x => {
      return { id: x.id, name: x.name };
    });
  }

  async searchRegion({ query }) {
    const resp = await domainService.getRegionsAutocomplete(query, 1);
    this.filteredRegions = resp.data.map(x => {
      return { id: x.id, name: x.name };
    });
  }

  async searchProvince({ query }) {
    const resp = await domainService.getProvincesAutocomplete(query);
    this.filteredProvinces = resp.data.map(x => {
      return { id: x.id, name: x.name };
    });
  }

  async searchCity({ query }) {
    const resp = await domainService.getCitiesAutocomplete(query);
    this.filteredCities = resp.data.map(x => {
      return { id: x.id, name: x.name };
    });
  }*/

}