import { Options, Vue } from "vue-class-component";
import { FilterMatchMode } from "primevue/api";

import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { TechniciansRoutesEnum } from "../../router";
import { providerTechniciansService } from "@services/providerTechnicians.service";

import {
  ActiveCell,
  DynamicTable,
  MyAutocomplete
} from "@components";
import { authStore } from "@/modules/auth/store";
import { ProviderTechnician } from "@/model/api/Provider";
import { Prop } from "vue-property-decorator";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})
export default class TechniciansPage extends Vue {
  readonly stateTableKey = 'TechniciansPage';
  filters: any = this.filtersSchema;

  @Prop()
  readonly providerId: number;

  get providerIdOrFallback(): number {
    if (authStore.getters.me.role == UserRoleEnum.PROVIDER) {
      return authStore.getters.me.entity_id;
    }
    return this.providerId;
  }

  get datatable(): any {
    return this.$refs.datatable;
  }

  beforeRouteLeave() {
    if (providerTechniciansService?.cancelPendingRequests) {
      providerTechniciansService.cancelPendingRequests();
    }
  }

  beforeRouteEnter(to, from, next) {
    if (!(from?.name as string)?.startsWith(TechniciansRoutesEnum.PREFIX)) {
      sessionStorage.removeItem('TechniciansPage');
    }
    next();
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  get service() {
    return providerTechniciansService;
  }

  goAdd() {
    this.$router.push({
      name: TechniciansRoutesEnum.TECHNICIANS_EDIT,
      params: { technicianId: 0, providerId: this.providerIdOrFallback }
    });
  }

  goToDetail(model: ProviderTechnician) {
    this.$router.push({
      name: TechniciansRoutesEnum.TECHNICIANS_EDIT,
      params: { technicianId: model.id, providerId: this.providerIdOrFallback }
    });
  }

  onDeleteRow(model: ProviderTechnician) {
    this.$confirmMessage(this.$t('confirm_delete'))
      .then(async response => {
        if (response) {
          this.$waitFor(
            async () => {
              await providerTechniciansService.dissociate(model.id, this.providerIdOrFallback);

              this.$successMessage(this.$t('data_saved_success'));
              this.applyFilters();
            },
            this.$t('generic_error')
          );
        }
      });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  customIndexRequest(p) {
    return providerTechniciansService.index({
      ...p,
      entity_id: this.providerIdOrFallback
    });
  }

  get cols() {
    return [
      {
        field: 'name',
        header: this.$t('user_name'),
        style: 'min-width: 10px; max-width: 150px'
      },
      {
        field: 'surname',
        header: this.$t('user_surname'),
        style: 'min-width: 10px; max-width: 100px'
      },
      /*
      {
        field: 'email',
        header: this.$t('generic.email_label'),
      },
      */
      {
        field: 'services_text',
        header: this.$t('provider.technicians.detail.services_label'),
        filterField: 'service_id'
      },
      // {
      //   field: 'regions_text',
      //   header: this.$t('provider.technicians.detail.regions_label'),
      //   filterField: 'region_id'
      // },
      {
        field: 'provinces_text',
        header: this.$t('provider.technicians.detail.provinces_label'),
        filterField: 'province_id'
      },
      // {
      //   field: 'cities_text',
      //   header: this.$t('provider.technicians.detail.cities_label'),
      //   filterField: 'city_id'
      // },
      {
        field: 'is_active_for_provider',
        header: this.$t('generic.input_state_label'),
        component: ActiveCell
      },
    ];
  }

  get filtersSchema() {
    return {
      name: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      surname: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      email: {
        matchMode: FilterMatchMode.CONTAINS
      },
      service_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      region_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      province_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      city_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      is_active: {
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  get filterYesNoOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('Si'), value: true },
      { name: this.$t('No'), value: false },
    ];
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('active_label'), value: true },
      { name: this.$t('inactive_label'), value: false },
    ];
  }
}